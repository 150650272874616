/* You can add global styles to this file, and also import other style files */
.color-light {
  color-scheme: light;
}

.text-yellow {
  color: #ffc107;
}

::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(22, 26, 88);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: rgb(45, 161, 123);
  border-radius: 50px;
}

/* */
.w-50 {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .w-50 {
    width: 100%;
  }
}

.right-39px {
  right: 39px;
}

.top-60 {
  top: 60%;
}

/* Tags Container */
.tagsContainer {
  height: 200px;
  overflow: hidden;
  overflow-y: auto !important;
}

/* text-restriction classes*/
.text-ellipsis-2 {
  line-height: 1.8rem;
  overflow: hidden;
  display: block;
  max-height: 4.4rem;
  -webkit-line-clamp: 2;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

/* Owl-carousel navigation buttons */
.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-prev {
  color: #fff;
  font-size: 23px !important;
  margin: 5px;
  padding: 4px 17px !important;
  background: #8055fd !important;
  cursor: pointer;
  border-radius: 32px !important;
}

/* Cart */
.content-scroll {
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.content-scroll p {
  font-size: 0.95rem;
}
